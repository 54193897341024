import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CertificateList } from '../models/certificate';
import { CourseFilter, CourseParametersFilter } from '../models/course';
import { EnrolmentPaymentList } from '../models/enrolment-payment';
import { EnrolmentWithCourseList } from '../models/enrolment-with-course';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class HelpTicketStore {
  private userService = inject(UserService);

  // Can accept null to identify it in case the type is technical support
  openTicketList?: EnrolmentPaymentList | EnrolmentWithCourseList | CertificateList | null;

  requestMapping: { [key: string]: Observable<any> } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'invoice-and-payments': this.userService.listCoursesPayments(),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    degrees: this.userService.listCoursesCertificates(
      new CourseParametersFilter(new CourseFilter([], [], [], [], undefined, undefined, undefined, [], false), '', -1)
    ),
    tutorship: this.userService.getCoursesRegistered(
      new CourseParametersFilter(
        new CourseFilter(
          [],
          [],
          [],
          [],
          undefined,
          undefined,
          undefined,
          [],
          false,
          undefined,
          false,
          undefined,
          true,
          false
        ),
        '',
        -1
      )
    )
  };
}
